import { useLocation, useOutletContext, useParams } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ExaminationRequests } from "services/StaticLookup/Breadcrumb";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { useState } from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { TestTypes } from "constants/TestTypes";
import { DATA_ID } from "constants/Details";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import {
  useGetAppointmentForConsultantQuery,
  useGetInspectionForConsultantQuery,
} from "modules/Consultant/apis/consultant-apis/contractor-apis";
import LabExaminationRequests from "./Lab";
import FieldExaminationRequests from "./Field";
import { useGetInspectionSoilReportQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { base64ToBlob } from "utils/ApiHelpers";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";

const ExaminationRequestsDetails = () => {
  const params = useParams();

  const { t } = useTranslation();

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  const { state } = useLocation();

  useEffect(() => {
    if (state?.activeTab) {
      setTabsActiveIndex(state?.activeTab);
    }
  }, []);

  const {
    data: inspection,
    isSuccess,
    isFetching,
  } = useGetInspectionForConsultantQuery(params.id);

  const isLab = inspection?.testType === TestTypes.LAB_TEST;
  const inspectionLayer = inspection?.inspectionLayersList[0];

  const isSoil = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_SOIL
  );
  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );

  const isRequestDone =
    inspection?.status === "SUCCEED" || inspection?.status === "FAILED";

  const isRequestCancelled =
    inspection?.status === "OUTDATED" || inspection?.status === "CANCELED";

  const { data: labTestAppointmentForConsultant } =
    useGetAppointmentForConsultantQuery(params.id);

  const {
    data: inspectionSoilReport,
    isLoading: isLoadingInspectionSoilReport,
  } = useGetInspectionSoilReportQuery(inspectionLayer?.id, {
    skip: !isLab || (!isABC && !isSoil),
  });

  const { globalAlert, onHideGlobalAlert } = useOutletContext();

  const openSoilPDFHandler = () => {
    const blob = base64ToBlob(inspectionSoilReport?.report);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  const ViweSoilReport = () => {
    return (
      <BaseButton
        disabled={!isRequestDone || !inspectionSoilReport?.report}
        sx={{ minWidth: "21rem" }}
        onClick={openSoilPDFHandler}
        isLoading={isLoadingInspectionSoilReport}
      >
        {"تحميل تقرير التربة"}
      </BaseButton>
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات الفحص - رقم الطلب	${inspection?.id}`}</title>
      </Helmet>

      <DashboardIntro
        title={t(` رقم الطلب ${inspection?.id || ""}`, {
          ns: "dashboard",
        })}
        description={"يمكنك متابعة تقدم اختبارات الفحص و الاطلاع على النتائج"}
        action={
          <Box>
            {!isRequestCancelled && (isSoil || isABC) && isLab && (
              <ViweSoilReport />
            )}
            <BaseAlert
              show={globalAlert.show}
              type={globalAlert.type}
              message={globalAlert.message}
              autoClose={3}
              interpolation={globalAlert.interpolation}
              destroy={onHideGlobalAlert}
            />
          </Box>
        }
        breadcrumbData={ExaminationRequests({
          id: inspection?.id,
        })}
      />

      <BaseCard
        mt={6}
        py={"2rem"}
        px={"5rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        {isFetching || !isSuccess ? (
          <BaseLoader />
        ) : (
          tabsActiveIndex === 1 &&
          (isLab ? (
            <LabExaminationRequests
              requestDetails={inspection}
              dataId={DATA_ID.CON_REQUESTS}
              labTestAppointment={labTestAppointmentForConsultant}
            />
          ) : (
            <FieldExaminationRequests
              requestDetails={inspection}
              dataId={DATA_ID.CON_REQUESTS}
              labTestAppointment={labTestAppointmentForConsultant}
            />
          ))
        )}
      </BaseCard>
    </>
  );
};

export default ExaminationRequestsDetails;
