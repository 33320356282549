import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import CollectSampleDataModal from "components/AllModalContent/CollectSampleDataModal/CollectSampleDataModal";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseTable from "core-ui/BaseTable/BaseTable";
import useAlert from "hooks/useAlert";
import {
  useAddAppointmentReportMutation,
  useSubmitQuestionnaireMutation,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import {
  useConfirmExaminationRecordFormMutation,
  useGetAppointmentDetailsForTechnicianQuery,
  useGetTechnicianInspectionQuery,
} from "modules/Technician/apis/inspection-apis/inspection-api";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AssignedRequestsDetail } from "services/StaticLookup/Breadcrumb";
import {
  ExaminationRecordFormData,
  ExaminationRecordWeatherFormData,
} from "services/StaticLookup/Radio";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { _scrollToTop } from "utils/DOM/DOM";
import ConfirmSubmitExaminationForm from "components/AllModalContent/ConfirmSubmitExaminationForm/ConfirmSubmitExaminationForm";
import RecordQuestionnaire from "modules/Technician/components/RecordQuestionnaire/RecordQuestionnaire";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import TechnicianConfirmationDoubleLayer from "components/TableUtilitiesComponents/TableDoubleLayerComponents/TechnicianConfirmationDoubleLayer";
import { assignedRequestTestsFeildSubmitted } from "services/StaticLookup/TableHeader/TableHeader";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import { valueChecker } from "utils/Numbers/Numbers";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";

let timer = null;

const FieldExaminationRecordForm = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    control,
    clearErrors,
    reset,
    setValue,
    setError,
    unregister,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const params = useParams();

  const { data: inspection } = useGetTechnicianInspectionQuery(params.id);

  const { data: appointmentData } = useGetAppointmentDetailsForTechnicianQuery(
    inspection?.id,
    { skip: !inspection?.id }
  );

  const { stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData(inspection);

  const [addAppointment, addAppointmentResponse] =
    useAddAppointmentReportMutation();

  const [submitQuestionnaire, submitQuestionnaireResponse] =
    useSubmitQuestionnaireMutation();

  const [postExaminationForm, postExaminationFormResponse] =
    useConfirmExaminationRecordFormMutation();

  const isFormFilled =
    submitQuestionnaireResponse.data?.consultantPresent === true ||
    submitQuestionnaireResponse.data?.consultantPresent === false
      ? true
      : false;

  const { alert, showAlertHandler } = useAlert();

  const navigate = useNavigate();

  const [showLeavePageModal, setShowLeavePageModal] = useState(false);
  const [showOnSubmitModal, setShowOnSubmitModal] = useState(false);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formData = watch();

  const submitBtnRef = useRef();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  const prepareStationsServerData = (data) => {
    let backendStations = stations.map((station) => {
      const thisStation = data[station.id];
      // prettier-ignore
      const areResultsRight = thisStation["confirmation-station"] == '1' ? true : false;
      return {
        id: station.id,
        ...(station.skipReason && { skipReasonConfirmation: areResultsRight }),
        // prettier-ignore
        maxProctorConfirmation: areResultsRight,
        // prettier-ignore
        optimumProctorConfirmation: areResultsRight,
        // prettier-ignore
        maxProctorCorrectedScore: areResultsRight ? null : valueChecker(thisStation['max-proctor']),
        // prettier-ignore
        optimumProctorCorrectedScore: areResultsRight ? null : valueChecker(thisStation['optimum-proctor']),
        // prettier-ignore
        maxProctor: station.maxProctor,
        // prettier-ignore
        optimumProctor: station.optimumProctor,
        // prettier-ignore
        tests: station.tests.map((test) => {
          return {
            inspectionTestId: test.inspectionTestId,
            technicianConfirmation: areResultsRight,
            scores: station.skipReason ? null : 
              areResultsRight ? 
                station.scoresList?.map(stationScore => stationScore.labScore) : 
                Object.values(thisStation.scores)?.filter(Boolean)?.map(v => valueChecker(v))

          };
        }   
      ),
      };
    });
    return backendStations;
  };

  const showErrorAlert = () => {
    showAlertHandler({
      show: true,
      type: AlertTypes.ERROR,
      message: ErrorCodes.UNKNOWN_ERROR,
    });
  };

  const onSubmitHandler = (data, event) => {
    // HERE WE SUBMIT THE FORM
    _scrollToTop();

    // FIRST API DATA CALL
    const presentationData = {
      consultantPresent: !!+data.consultantPresent,
      contractorPresent: !!+data.contractorPresent,
      labPresent: !!+data.labPresent,
      layerReady: !!+data.layerReady,
      weather: data.weather,
      havaNotes: !!+data.havaNotes,
      locationIsCorrect: !!+data.locationIsCorrect,
      delayCloseRequest: !!+data.delayCloseRequest,
      notes: data.notes,
    };

    // SECOND API DATA CALL
    const examinationFormBackendBody = prepareStationsServerData(data);

    setIsFormSubmitting(true);

    // NEW INTEGRATION LOGIC HERE

    submitQuestionnaire({
      body: presentationData,
      inspectionId: inspection.id,
    })
      .unwrap()
      .then(() => {
        postExaminationForm({
          body: { stations: examinationFormBackendBody },
          inspectionId: inspection.id,
        })
          .unwrap()
          .then((responseData) => {
            showAlertHandler({
              show: true,
              type: AlertTypes.SUCCESS,
              message: SuccessCodes.SUCCESS_EXAMINATION_RECORD_FORM,
            });
            timer = setTimeout(() => goBack(), 2000);
            setIsFormSubmitting(false);
          })
          .catch(() => {
            showErrorAlert();
            setIsFormSubmitting(false);
          });
      })
      .catch(() => {
        showErrorAlert();
        setIsFormSubmitting(false);
      });
  };

  const onErrorHandler = (errors) => {
    console.log("errors: ", errors);
  };

  const onCatchLeavePageHandler = () => {
    setShowLeavePageModal(true);
  };

  const onLeavePageHandler = () => {
    setShowLeavePageModal(false);
  };

  const onOpenSubmitModal = () => {
    setShowOnSubmitModal(true);
  };

  const onCloseSubmitModal = () => {
    setShowOnSubmitModal(false);
  };

  const onSubmit = () => {
    submitBtnRef.current.click();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة - رقم الطلب ${params.id}`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <DashboardIntro
        title={"نموذج محضر الفحص"}
        description={"رجاءً قم بتعبئة البيانات التالية"}
        breadcrumbData={AssignedRequestsDetail({ id: params.id })}
        action={
          <DashboardIntroAction>
            <BaseAlert
              type={alert.type}
              show={alert.show}
              message={alert.message}
            />
          </DashboardIntroAction>
        }
      />

      <BaseModal
        show={showLeavePageModal}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <CollectSampleDataModal onHide={onLeavePageHandler} />
      </BaseModal>

      <BaseModal
        show={showOnSubmitModal}
        py={"6rem"}
        px={"4rem"}
        onHide={onCloseSubmitModal}
      >
        <ConfirmSubmitExaminationForm
          confirm={onSubmit}
          onHide={onCloseSubmitModal}
          isLoading={isFormSubmitting}
        />
      </BaseModal>

      <BaseCard
        mt={6}
        py={"2rem"}
        px={"3rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        <BaseCardTitle mt={5}>الحضور</BaseCardTitle>

        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <RecordQuestionnaire
            register={register}
            errors={errors}
            control={control}
            ExaminationRecordFormData={ExaminationRecordFormData}
            ExaminationRecordWeatherFormData={ExaminationRecordWeatherFormData}
            t={t}
          />
          <Box mt={5}>
            <BaseTableDoubleCard
              doubleLayer={(element) => ({
                visibility: true,
                component: <TechnicianConfirmationDoubleLayer />,
              })}
              title={"النتائج"}
              description={
                "رجاء قم بمراجعة نتائج الاختبار التي تم رصدها من قبل المختبر والتأكيد على صحتها"
              }
              injectProps={{
                control,
                register,
                watch,
                errors,
                clearErrors,
                setError,
                setValue,
                formData,
                errors,
                reset,
                setMySpanColumns,
                mySpanColumns,
                setMyHiddenColumns,
                myHiddenColumns,
                submittedFromLabAt: inspection?.submittedAt,
              }}
              tableHeader={assignedRequestTestsFeildSubmitted("tech")}
              content={stations}
              isLoading={isStationsFetching}
            />
          </Box>

          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"end"}
            gap={3}
            marginY={4}
          >
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              variant={"secondary"}
              onClick={onCatchLeavePageHandler}
              disabled={isFormSubmitting && !isFormFilled}
            >
              {t("تراجع", { ns: "common" })}
            </BaseButton>

            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              onClick={onOpenSubmitModal}
              isLoading={isFormSubmitting && !isFormFilled}
            >
              {t("submit", { ns: "common" })}
            </BaseButton>
            <button className="hidden" ref={submitBtnRef}>
              HIDDEN SUBMIT BUTTON
            </button>
          </Box>
        </form>
      </BaseCard>
    </>
  );
};

export default FieldExaminationRecordForm;
