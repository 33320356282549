import { useState } from "react";
import { Box, Typography } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import classes from "../../DrillingLicensesData/DashboardDrillingLicensesData.module.scss";
import { publicAssetsPath } from "utils/Paths/Paths";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import { base64ToBlob } from "utils/ApiHelpers";
import BaseFileDataItem from "core-ui/BaseFileDataItem/BaseFileDataItem";
import BaseViewModalDataItem from "core-ui/BaseViewModalDataItem/BaseViewModalDataItem";
import BarcodesModal from "components/AllModalContent/BarcodesModal/BarcodesModal";
import { defaultEmptyValue } from "constants/Empty";
import {
  useGetInspectionAsphaltReportQuery,
  useGetInspectionSoilReportQuery,
} from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { useGetAspeltBaseCourseAttachmentQuery } from "modules/Contractor/apis/inspection-apis/asphalt-api";
import { useGetSamplesForAdminQuery } from "modules/Admin/apis/inspection-apis/inspection-api";
import { useParams } from "react-router-dom";
import { REQUEST_TYPE } from "constants/RequestType";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";

const AttachmentDataLab = ({ inspection, attachment }) => {
  const [previewImage, setPreviewImage] = useState(false);
  const [showBardcodes, setShowBardcodes] = useState(false);
  const inspectionLayer = inspection?.inspectionLayersList?.[0];
  const isAsphalt =
    inspectionLayerFlagChecker(
      inspectionLayer?.flags,
      InspectionLayerFlags.IS_ASPHALT
    ) ?? undefined;
  const isSoil =
    inspectionLayerFlagChecker(
      inspectionLayer?.flags,
      InspectionLayerFlags.IS_SOIL
    ) ?? undefined;
  const isABC =
    inspectionLayerFlagChecker(
      inspectionLayer?.flags,
      InspectionLayerFlags.IS_ABC
    ) ?? undefined;

  const params = useParams();

  const { data: inspectionAsphaltReport } = useGetInspectionAsphaltReportQuery(
    inspectionLayer?.id,
    {
      skip:
        !isAsphalt ||
        (inspection?.status !== REQUEST_TYPE.SUCCEED &&
          inspection?.status !== REQUEST_TYPE.FAILED),
    }
  );

  const { data: inspectionSoilReport } = useGetInspectionSoilReportQuery(
    inspectionLayer?.id,
    {
      skip:
        (!isABC && !isSoil) ||
        (inspection?.status !== REQUEST_TYPE.SUCCEED &&
          inspection?.status !== REQUEST_TYPE.FAILED),
    }
  );

  const { data: samplesData } = useGetSamplesForAdminQuery(params.id);

  const hasBarcodes = samplesData?.samples?.length > 0;

  const previewImageHandler = () => {
    setPreviewImage(true);
  };

  const closePreviewImageHandler = () => {
    setPreviewImage(false);
  };

  const openPDFHandler = () => {
    var blob = base64ToBlob(attachment);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  const onLeaveCardHandler = () => setShowBardcodes(false);

  const inspectionLayersId = inspectionLayer?.id ?? undefined;

  const { data: AspeltBaseCourseAttachment } =
    useGetAspeltBaseCourseAttachmentQuery(inspectionLayersId, {
      skip: !isAsphalt,
    });

  return !inspection ? (
    <BaseLoader />
  ) : (
    <>
      <BaseModal
        show={showBardcodes}
        py={"4rem"}
        px={"4rem"}
        onHide={onLeaveCardHandler}
        closeIcon
        title={`رموز العينات (barcode) للطلب رقم #${inspection.id}`}
        mainContainerSx={{
          width: {
            lg: "80rem",
          },
        }}
      >
        <BarcodesModal
          onHide={onLeaveCardHandler}
          samples={samplesData?.samples}
          variant="ADMIN"
        />
      </BaseModal>
      <BaseModal
        show={previewImage}
        py={"2rem"}
        px={"4rem"}
        onHide={closePreviewImageHandler}
        closeIcon
        title={"صور النتائج"}
        modalContentWrapperSx={{ textAlign: "center" }}
      >
        <img
          src={samplesData?.collectSampleDto?.attachment}
          className="img-fluid"
        />
      </BaseModal>
      <Box className={classes["drilling-data"]} mt={6} ml={4}>
        <Typography className={classes["drilling-data__title"]}>
          نموذج جمع العينة
        </Typography>
        <Box className={classes["drilling-data__wrapper"]} mt={4}>
          <Typography className={classes["drilling-data__title"]}>
            تفاصيل العينة
          </Typography>
          <WrapperContentGrid columnsWidth={24} gap={4}>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                مصدر المواد
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {samplesData?.collectSampleDto?.materialSource || "لا يوجد"}{" "}
              </Typography>
            </Box>

            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                عدد العينات
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {samplesData?.collectSampleDto?.numberOfSamples || "لا يوجد"}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <Box className={classes["drilling-data__wrapper"]}>
          <Typography className={classes["drilling-data__title"]}>
            تفاصيل الجمع
          </Typography>
          <WrapperContentGrid columnsWidth={24} gap={4}>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                درجة الحرارة
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {samplesData?.collectSampleDto?.weatherCondition || "لا يوجد"}
              </Typography>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                مكان أخذ العينة
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {samplesData?.collectSampleDto?.location || "لا يوجد"}
              </Typography>
            </Box>
            {hasBarcodes && (
              <Box className={classes["drilling-data__item"]}>
                <Typography className={classes["drilling-data__label"]}>
                  رموز العينات (barcode)
                </Typography>

                <BaseViewModalDataItem
                  value={"barcodes"}
                  onClick={() => setShowBardcodes(true)}
                />
              </Box>
            )}
          </WrapperContentGrid>
        </Box>
        <Box className={classes["drilling-data__wrapper"]}>
          <Box className={"request-data-details__item"} mt={6}>
            <Typography className={"request-data-details__label"}>
              هل كان المقاول متواجد في الموقع عند جمع العينة؟
            </Typography>
            <Typography className={"request-data-details__value"}>
              {samplesData?.collectSampleDto &&
              samplesData?.collectSampleDto?.contractorPresent !== null
                ? !!samplesData?.collectSampleDto?.contractorPresent
                  ? "نعم"
                  : "لا"
                : "لا يوجد"}
            </Typography>
          </Box>
        </Box>
        <hr />
        <Box className={classes["drilling-data__wrapper"]}>
          <Typography className={classes["drilling-data__title"]}>
            صورة العينة
          </Typography>
          <WrapperContentGrid columnsWidth={24} gap={4}>
            {samplesData?.collectSampleDto?.attachment ? (
              <BaseDataItem
                valueComponentAs={"div"}
                value={
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={0.5}
                    onClick={previewImageHandler}
                  >
                    <Typography className="request-data-details__link">
                      الصورة
                    </Typography>
                    <img
                      src={publicAssetsPath("icons/popout-svgrepo.svg")}
                      width="auto"
                    />
                  </Box>
                }
              />
            ) : (
              <Typography className={"request-data-details__value"}>
                لا يوجد
              </Typography>
            )}
          </WrapperContentGrid>
        </Box>

        <hr />

        <Box className={classes["drilling-data__wrapper"]}>
          <Typography className={classes["drilling-data__title"]}>
            المرفقات
          </Typography>

          <WrapperContentGrid columnsWidth={30} gap={2}>
            <Box>
              <Typography className={"request-data-details__label"}>
                نتائج الاختبارات
              </Typography>
              {attachment ? (
                <>
                  <BaseDataItem
                    valueComponentAs={"div"}
                    value={
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={0.5}
                        onClick={openPDFHandler}
                      >
                        <Typography className="request-data-details__link">
                          المرفق
                        </Typography>
                        <img
                          src={publicAssetsPath("icons/popout-svgrepo.svg")}
                          width="auto"
                        />
                      </Box>
                    }
                  />
                </>
              ) : (
                <Typography className={"request-data-details__value"}>
                  {defaultEmptyValue}
                </Typography>
              )}
            </Box>

            {isAsphalt && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  ملف تصميم خلطة الاسفلت (Asphalt Mix Design)
                </Typography>
                {AspeltBaseCourseAttachment ? (
                  <BaseFileDataItem
                    label="Asphalt.pdf"
                    hideMainLabel
                    attachment={AspeltBaseCourseAttachment}
                  />
                ) : (
                  <Typography className={"request-data-details__value"}>
                    {defaultEmptyValue}
                  </Typography>
                )}
              </Box>
            )}

            {isAsphalt && inspectionAsphaltReport?.report && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  تقرير الأسفلت
                </Typography>
                <BaseFileDataItem
                  label="Asphalt Summary Test Report.pdf"
                  hideMainLabel
                  attachment={inspectionAsphaltReport?.report}
                />
              </Box>
            )}

            {(isSoil || isABC) && inspectionSoilReport?.report && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  تقرير التربة
                </Typography>
                <BaseFileDataItem
                  label="Soil Report.pdf"
                  hideMainLabel
                  attachment={inspectionSoilReport?.report}
                />
              </Box>
            )}
          </WrapperContentGrid>
        </Box>
      </Box>
    </>
  );
};

export default AttachmentDataLab;
