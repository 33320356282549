import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import Cookies from "js-cookie";
import { useState } from "react";
import { handleOpenPdf } from "utils/File/File";

const CookieConsent = (props) => {
  const { palette } = useTheme();

  const cookieConsentValue = Cookies.get("cookie-consent");
  const shouldShowCookieConsent = cookieConsentValue === undefined;

  const [showCookieConsent, setShowCookieConsent] = useState(
    shouldShowCookieConsent
  );

  const accept = () => {
    Cookies.set("cookie-consent", true);
    setShowCookieConsent(false);
  };
  const decline = () => {
    Cookies.set("cookie-consent", false);
    setShowCookieConsent(false);
  };

  return (
    showCookieConsent && (
      <Box
        sx={{
          background: palette.grayDark.color12,
          width: "100%",
          color: "white",
          fontSize: "1.4rem",
          padding: "2rem",
          position: "fixed",
          bottom: 0,
          zIndex: 99999,
        }}
      >
        <Typography
          sx={{
            fontSize: "inherit",
            textAlign: "center",
            lineHeight: 1.7,
          }}
        >
          نحن نستخدم ملفات تعريف الارتباط لتقديم أفضل تجربة ممكنة على موقعنا.
          لمعرفة المزيد ، الرجاء زيارة{" "}
          <Typography
            component={"span"}
            sx={{
              fontSize: "inherit",
              cursor: "pointer",
              color: "base.darkBlue",
            }}
            onClick={handleOpenPdf.bind(
              null,
              `${process.env.PUBLIC_URL}/privacy-policy.pdf`
            )}
          >
            سياسة الخصوصية
          </Typography>{" "}
          . من خلال الاستمرار في استخدام هذا الموقع ، أو إغلاق هذه الرسالة، فإنك
          توافق على استخدامنا لملفات تعريف الارتباط.
        </Typography>
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <BaseButton
            variant="primary"
            sx={{ fontSize: "1.4rem" }}
            onClick={accept}
          >
            قبول الكل
          </BaseButton>
        </Box>
      </Box>
    )
  );
};

export default CookieConsent;
