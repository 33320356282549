import React from "react";
import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { publicAssetsPath } from "utils/Paths/Paths";
import { defaultEmptyValue } from "constants/Empty";
import LocationField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LocationField/LocationField";
import MixDesignDetails from "modules/Laboratory/components/MixDesignDetails/MixDesignDetails";
import LabExaminationRequestTests from "./LabExaminationRequestTests";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";
import Notes from "components/Notes/Notes";
import { useGetSubTestsForAdminQuery } from "modules/Admin/apis/inspection-apis/inspection-api";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import MultipleLicensesDataItem from "components/MultipleLicensesDataItem/MultipleLicensesDataItem";
import MultipleConsultantDataItem from "components/MultipleConsultantDataItem/MultipleConsultantDataItem";
import LicenseLink from "components/LicenseLink/LicenseLink";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";

function RequestDataLab({
  inspection,
  dataId,
  inspectionTestsData,
  isFetching,
  contractorCompanyInfo,
  technicianData,
  appointmentData,
  ...props
}) {
  const inspectionLayer = inspection?.inspectionLayersList?.[0];

  const asphalet = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );

  const hasSubTestsTest = inspectionTestsData?.map((inspection) =>
    inspection?.inspectionTests?.find((test) => test?.containSubtests)
  );
  const hasSubTestsTestId = hasSubTestsTest?.[0]?.id ?? undefined;

  const inspectionTests = inspectionTestsData?.[0]?.inspectionTests;

  const { data: subTests } = useGetSubTestsForAdminQuery(hasSubTestsTestId, {
    skip: !hasSubTestsTestId,
  });

  const singleLicense = inspection?.licenses?.[0];

  const isSingleLicense = inspection?.licenses?.length === 1;

  return (
    <Box>
      <Box className={"request-data-details"} mt={4} ml={4} mr={4}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.id || "لا يوجد "}
              </Typography>
            </Box>
            {isSingleLicense ? (
              <LicenseLink license={inspection?.licenses?.[0]} />
            ) : (
              <MultipleLicensesDataItem
                requestId={inspection?.id}
                data={inspection?.licenses}
              />
            )}
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الفحص 
              </Typography>
              <BaseStatus
                status={inspection?.status}
                dataId={dataId}
                testType={inspection.testType}
                appointment={inspection.appointment}
              />
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الإنشاء
              </Typography>
              <Typography
                component={"div"}
                className={"request-data-details__value"}
              >
                {formatDateYYYYMMDD(inspection?.createdAt) || "لا يوجد"}
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اختبار مواد الأعمال المدنية{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.name || "لا يوجد "}
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نوع المسار
              </Typography>
              <BaseTypeTag type={inspection?.testType} />
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                {`موقع أخذ العينة`}
              </Typography>
              {inspectionLayer?.latitude ? (
                <LocationField
                  location={`https://maps.google.com/?q=${inspectionLayer?.latitude},${inspectionLayer?.longitude}`}
                />
              ) : (
                <Typography className={"request-data-details__value"}>
                  {defaultEmptyValue}
                </Typography>
              )}
            </Box>
            {isABC && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  درجة التربة
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {inspectionLayer?.soilGrade || defaultEmptyValue}
                </Typography>
              </Box>
            )}
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                موعد جمع العينة
              </Typography>
              <Typography
                component={"div"}
                className={"request-data-details__value"}
              >
                <DisplayRequestAppointmentDate
                  appointmentDate={appointmentData?.scheduledDate}
                  scheduledAppointment={appointmentData?.scheduledTime}
                />
              </Typography>
            </Box>
            {!asphalet && inspectionLayer?.soilClassification && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  تصنيف التربة
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {inspectionLayer?.soilClassification}
                </Typography>
              </Box>
            )}

            {asphalet && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  Asphalt Grade
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {inspectionLayer?.aspeltMixDesignDetailDto?.aspeltGrade ||
                    "لا يوجد "}
                </Typography>
              </Box>
            )}
            {inspectionLayer?.requiresAspeltReport && asphalet && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  Supplier
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {inspectionLayer?.aspeltMixDesignDetailDto?.supplier ||
                    "لا يوجد "}
                </Typography>
              </Box>
            )}
          </WrapperContentGrid>
        </Box>
        <hr />
        {inspectionLayer?.requiresAspeltReport && asphalet && (
          <>
            <Box my={5}>
              <Notes notes={inspectionLayer?.aspeltMixDesignDetailDto?.notes} />
            </Box>

            <Box my={4}>
              <MixDesignDetails
                aspeltMixDesignDetailDto={
                  inspectionLayer?.aspeltMixDesignDetailDto
                }
              />
            </Box>
            <hr />
          </>
        )}
        {inspection?.cancellationReason && (
          <>
            <BaseAlert
              mb={5}
              mt={5}
              show={true}
              labelSx={{ fontWeight: "bold", color: "black" }}
              type={AlertTypes.WARNING}
              icon={false}
            >
              <Typography
                sx={{
                  fontSize: "inherit !important",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                سبب إلغاء الطلب:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "normal",
                }}
              >
                {inspection?.cancellationReason}
              </Typography>
            </BaseAlert>
            <hr />
          </>
        )}
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            الاختبارات
          </Typography>
          <Box mt={4}>
            <LabExaminationRequestTests
              inspectionTestsData={inspectionTests}
              subTests={subTests}
              data={inspection?.inspectionTestDTOList}
              isFetching={isFetching}
              testType={inspection?.testType}
              asphalet={asphalet}
            />
          </Box>
        </Box>

        <hr />

        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات المختبر {" "}
          </Typography>
          <WrapperContentGrid columnsWidth={26} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.labName || "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                موقع المختبر{" "}
              </Typography>
              <Typography
                className={"request-data-details__value"}
                sx={{
                  fontWeight: "bold !important",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {inspection?.labLocation ? (
                  <>
                    <a
                      href={inspection?.labLocation}
                      target="_blank"
                      className="request-data-details__link"
                    >
                      الموقع
                    </a>
                    <img
                      src={publicAssetsPath("icons/popout-svgrepo.svg")}
                      width="auto"
                    />
                  </>
                ) : (
                  defaultEmptyValue
                )}
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الإسناد
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(inspection?.labAssignedAt) || "لا يوجد"}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>

        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات الاستشاري 
          </Typography>
          {isSingleLicense ? (
            <WrapperContentGrid columnsWidth={26} gap={4}>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  الاسم{" "}
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {singleLicense?.consultantName || "لا يوجد "}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  رقم الجوال{" "}
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {formatPhoneNumber(singleLicense?.consultantMobile) ||
                    "لا يوجد "}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  البريد الإلكتروني{" "}
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {singleLicense?.consultantEmail || "لا يوجد "}
                </Typography>
              </Box>
            </WrapperContentGrid>
          ) : (
            <MultipleConsultantDataItem
              requestId={inspection?.id}
              data={inspection?.licenses}
            />
          )}
        </Box>

        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات شركة المقاولات
          </Typography>
          <WrapperContentGrid columnsWidth={26} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اسم شركة المقاولات
              </Typography>
              <Typography className={"request-data-details__value"}>
                {contractorCompanyInfo?.contractorCompany || "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الجوال{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatPhoneNumber(contractorCompanyInfo?.contractorMobile) ||
                  "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {contractorCompanyInfo?.contractorEmail || "لا يوجد "}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>

        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات فني الرقابة
          </Typography>
          <WrapperContentGrid columnsWidth={26} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {technicianData?.name || "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {technicianData?.email || "لا يوجد "}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
      </Box>
    </Box>
  );
}

export default RequestDataLab;
