import { useTheme } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCameraMultiple from "core-ui/BaseCameraMultiple/BaseCameraMultiple";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import { defaultEmptyValue } from "constants/Empty";
import BaseVerticalTimeline from "core-ui/BaseVerticalTimeline/BaseVerticalTimeline";
import useBaseCameraMultiple from "hooks/useBaseCameraMultiple";
import useVerticalTimeline from "hooks/useVerticalTimeline";
import { useGetProctorDataQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import {
  useGetAppointmentDetailsForTechnicianQuery,
  useLazyGetTechnicianAttachmentQuery,
  useSaveRecordFormAttachmentMutation,
} from "modules/Technician/apis/inspection-apis/inspection-api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { renderDateTimeInAMPM } from "utils/Date/Date";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import PreviewAttachment from "./PreviewAttachment/PreviewAttachment";
import PreviewMultipleAttachments from "./PreviewMultipleAttachments/PreviewMultipleAttachments";
import BaseModal from "core-ui/BaseModal/BaseModal";
import Notes from "components/Notes/Notes";

const FieldExaminationTestsActions = (props) => {
  const { completeStage, onHide, vTimelineRef, reanimate } =
    useVerticalTimeline();

  const [showBaseCameraMultiple, setShowBaseCameraMultiple] = useState(false);

  const [photos, setPhotos] = useState([]);

  const { palette } = useTheme();

  const params = useParams();

  const [previewImage, setPreviewImage] = useState(false);

  const navigate = useNavigate();

  const [uploadAttachment, uploadAttachmentResponse] =
    useSaveRecordFormAttachmentMutation();

  const {
    isFormFilled,
    appointmentReportData,
    isAppointmentReportDataFetching,
    questionnaireRecoord,
    isQuestionnaireRecoordFetching,
    t,
  } = props;

  const inspectionLayer = props?.inspectionLayer;

  const { data: proctorData } = useGetProctorDataQuery(inspectionLayer?.id, {
    skip: !inspectionLayer?.id,
  });

  const stationsMandatoryNumber = 1;

  const [getAttachment, getAttachmentResponse] =
    useLazyGetTechnicianAttachmentQuery();

  const { data: appointmentData, refetch } =
    useGetAppointmentDetailsForTechnicianQuery(props.inspection.id);

  useEffect(() => {
    refetch();
  }, [appointmentReportData]);

  useEffect(() => {
    refetch();
  }, [questionnaireRecoord]);

  const { fetchAttachments, isAttachmentIdsEmpty, isFetchingAttachments } =
    useBaseCameraMultiple(
      getAttachment,
      appointmentData?.attachmentIds,
      setPhotos,
      false
    );

  const verticalItemTitleSx = {
    fontWeight: "500",
    fontSize: "1.8rem",
    color: `${palette.grayDark.color}`,
    marginBottom: "1.8rem",
  };

  const verticalItemDescSx = {
    fontSize: "1.6rem",
    color: `${palette.grayDark.color}`,
  };

  useEffect(() => {
    if (
      !!appointmentReportData?.attachedAt ||
      !!appointmentReportData?.attachmentBase64
    ) {
      fetchAttachments();
      completeStage(1);
    }
    if (isFormFilled) {
      completeStage(2);
      onHide();
    }
  }, [inspectionLayer, appointmentReportData, appointmentData]);

  useEffect(() => {
    let timer = setTimeout(reanimate, 200);
    return () => clearTimeout(timer);
  }, [
    showBaseCameraMultiple,
    photos,
    uploadAttachmentResponse.isLoading,
    uploadAttachmentResponse.isSuccess,
  ]);

  const navigateToExaminationFormPage = () => {
    navigate(`/field-examination-requests/${params.id}/examination-form`);
  };

  const uploadFn = (formData) => {
    uploadAttachment({
      body: formData,
      appointmentId: props.appointmentId,
    })
      .unwrap()
      .then((_) => {})
      .catch((err) => {});
  };

  const attachedAtDateObject = new Date(appointmentReportData?.attachedAt);
  const attachedAtHours = attachedAtDateObject.getHours();
  const attachedAtMinutes = attachedAtDateObject.getMinutes();
  const attachedAtSeconds = attachedAtDateObject.getSeconds();
  const formattedAttachedAtTimes = renderDateTimeInAMPM(
    `${attachedAtHours}:${attachedAtMinutes}:${attachedAtSeconds}`
  );

  const maxProctor = () => {
    if (proctorData?.["maxProctor"] === null) return defaultEmptyValue;
    if (proctorData?.maxProctorCorrectedScore === null)
      return `${proctorData?.["labMaxProctor"]} صحيحة`;
    return `${proctorData?.["labMaxProctor"]} غير صحيحة, النتيجة الصحيحة: ${proctorData?.maxProctorCorrectedScore}`;
  };
  const optimumProctor = () => {
    if (proctorData?.["optimumProctor"] === null) return defaultEmptyValue;

    if (proctorData?.optimumProctorCorrectedScore === null)
      return `${proctorData?.["labOptimumProctor"]} صحيحة`;
    return `${proctorData?.["labOptimumProctor"]} غير صحيحة, النتيجة الصحيحة: ${proctorData?.optimumProctorCorrectedScore}`;
  };

  const previewImageHandler = () => {
    setPreviewImage(true);
  };

  const closePreviewImageHandler = () => {
    setPreviewImage(false);
  };

  return (
    <>
      <BaseModal
        show={previewImage}
        py={"2rem"}
        px={"4rem"}
        onHide={closePreviewImageHandler}
        closeIcon
        title={"صور النتائج"}
        modalContentWrapperSx={{ textAlign: "center" }}
      >
        <img
          src={props.appointmentReportData?.attachmentBase64}
          className="img-fluid"
        />
      </BaseModal>

      <BaseVerticalTimeline sx={{ marginTop: 3 }} ref={vTimelineRef}>
        {appointmentReportData?.attachedAt ? (
          <Box>
            <BaseDataWrapper
              title={"نتائج الفحص"}
              titleStyles={{ marginBottom: "3rem" }}
            >
              {props.appointmentReportData?.attachmentBase64 && (
                <PreviewAttachment
                  attachedAt={appointmentReportData.attachedAt}
                  formattedAttachedAtTimes={formattedAttachedAtTimes}
                  previewImageHandler={previewImageHandler}
                />
              )}
              {!isAttachmentIdsEmpty && (
                <BaseDataItem
                  label={"تاريخ أخد الصور"}
                  value={`${formatDateYYYYMMDD(
                    appointmentReportData.attachedAt
                  )} ${formattedAttachedAtTimes}`}
                />
              )}
            </BaseDataWrapper>
            {!isAttachmentIdsEmpty && (
              <PreviewMultipleAttachments
                photos={photos}
                isReadonlyMode={!isAttachmentIdsEmpty}
                isFetchingAttachments={isFetchingAttachments}
                uploadFn={uploadFn}
                isAttachmentIdsEmpty={isAttachmentIdsEmpty}
                uploadResponse={uploadAttachmentResponse}
              />
            )}
          </Box>
        ) : (
          <Box sx={{ marginBottom: "6rem !important" }}>
            <Typography sx={{ ...verticalItemTitleSx }}>نتائج الفحص</Typography>
            <Typography sx={{ ...verticalItemDescSx }}>
              يرجى التقاط صور لنتائج الفحص الحقلي
            </Typography>

            {!showBaseCameraMultiple ? (
              <BaseButton
                sx={{ marginTop: "2.5rem" }}
                onClick={() => setShowBaseCameraMultiple(true)}
                disabled={!!!props.inspection.submittedAt}
              >
                أخذ صور لنتائج الفحص
              </BaseButton>
            ) : (
              <BaseCameraMultiple
                photos={photos}
                isUploading={
                  uploadAttachmentResponse.isLoading ||
                  isAppointmentReportDataFetching
                }
                isReadonlyMode={false}
                requiredLength={stationsMandatoryNumber}
                uploadFn={uploadFn}
                uploadResponse={uploadAttachmentResponse}
              />
            )}
          </Box>
        )}
        {isFormFilled ? (
          <Box sx={{ marginBottom: "3rem !important" }}>
            <Typography sx={{ ...verticalItemTitleSx }}>محضر الفحص</Typography>
            <Stack direction={"column"} gap={5} flexWrap={"wrap"}>
              <BaseDataItem
                label={
                  "هل كان الاستشاري متواجد في الموقع عند إجراء الاختبار، وفي الوقت المحدد؟"
                }
                value={questionnaireRecoord?.consultantPresent ? "نعم" : "لا"}
              />
              <BaseDataItem
                label={
                  "هل كان المختبر متواجد في الموقع عند إجراء الاختبار، وفي الوقت المحدد؟"
                }
                value={questionnaireRecoord?.labPresent ? "نعم" : "لا"}
              />
              <BaseDataItem
                label={
                  "هل كان المقاول متواجد في الموقع عند إجراء الاختبار، وفي الوقت المحدد؟"
                }
                value={questionnaireRecoord?.contractorPresent ? "نعم" : "لا"}
              />
              {questionnaireRecoord &&
              questionnaireRecoord?.layerReady !== null ? (
                <BaseDataItem
                  label={"هل كانت الطبقة جاهزة للاختبار؟"}
                  value={questionnaireRecoord?.layerReady ? "نعم" : "لا"}
                />
              ) : null}
              {questionnaireRecoord &&
              questionnaireRecoord?.weather !== null ? (
                <BaseDataItem
                  label={"ما هي حالة الطقس وقت الاختبار؟"}
                  value={t(questionnaireRecoord?.weather, {
                    ns: "common",
                  })}
                />
              ) : null}
              {questionnaireRecoord &&
              questionnaireRecoord?.locationIsCorrect !== null ? (
                <BaseDataItem
                  label={
                    "هل بيانات الموقع مطابقة للبيانات المدخلة بالطلب على المنصة؟"
                  }
                  value={questionnaireRecoord?.locationIsCorrect ? "نعم" : "لا"}
                />
              ) : null}
              {questionnaireRecoord &&
              questionnaireRecoord?.havaNotes !== null ? (
                <BaseDataItem
                  label={"هل يوجد ملاحظات على الموقع؟"}
                  value={questionnaireRecoord?.havaNotes ? "نعم" : "لا"}
                />
              ) : null}
              {questionnaireRecoord &&
              questionnaireRecoord?.delayCloseRequest !== null ? (
                <BaseDataItem
                  label={
                    "هل يوجد تأخير في اغلاق الطلب في الوقت المحدد؟ أذكر السبب في الملاحظات؟"
                  }
                  value={questionnaireRecoord?.delayCloseRequest ? "نعم" : "لا"}
                />
              ) : null}
              <hr />
              {questionnaireRecoord && questionnaireRecoord?.notes !== null ? (
                <Notes notes={questionnaireRecoord?.notes} />
              ) : null}
            </Stack>
          </Box>
        ) : (
          <Box>
            <Typography sx={{ ...verticalItemTitleSx }}>محضر الفحص</Typography>
            <Typography sx={{ ...verticalItemDescSx }}>
              يرجى تعبئة النموذج التالي
            </Typography>
            <BaseButton
              sx={{ marginTop: "2.5rem" }}
              disabled={!appointmentReportData?.attachedAt}
              variant={!appointmentReportData?.attachedAt ? "disabled" : ""}
              onClick={navigateToExaminationFormPage}
            >
              تعبئة النموذج
            </BaseButton>
          </Box>
        )}
      </BaseVerticalTimeline>
    </>
  );
};

export default FieldExaminationTestsActions;
