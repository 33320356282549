import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { adminDrillingLicenses } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { TestTypes } from "constants/TestTypes";
import Search from "components/Search/Search";
import { useLazyGetAdminLicensesQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import { useEffect, useState } from "react";
import { isContentReady } from "utils/Array/Arrays";
import { licenseYearsData } from "services/StaticLookup/LicensesYears";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import BaseButton from "core-ui/BaseButton/BaseButton";
import AdminAddAttachment from "components/AllModalContent/AdminAddAttachment/AdminAddAttachment";
import { useAddNewLicenseMutation } from "modules/Admin/apis/contractor-apis/companies-apis";
import useAlert from "hooks/useAlert";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import messages from "services/AlertMessages/messages";
import { AlertTypes } from "constants/AlertTypes";
import { Box, Stack } from "@mui/material";

const AdminDrillingLicenses = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseYear, setLicenseYear] = useState("");

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getAdminLicenses, getAdminLicensesResponse] =
    useLazyGetAdminLicensesQuery();

  useEffect(() => {
    var timer = setTimeout(
      getAdminLicenses.bind(null, {
        page: licenseNumber ? 0 : page,
        size: pageSize,
        sortId: "id,desc",
        licenseNumber,
        licenseYear,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, licenseNumber, licenseYear]);

  const getLicenseNumber = (licenseNumber) => {
    setLicenseNumber(licenseNumber);
  };
  const getLicenseYear = (licenseYear) => {
    setLicenseYear(licenseYear.name || "");
  };
  const isNoSearchData =
    (licenseNumber || licenseYear) &&
    !isContentReady(getAdminLicensesResponse.data?.content);
  const emptyTitle = isNoSearchData
    ? t("No License Search")
    : t("No new license");
  const emptyDesc = isNoSearchData
    ? t("No Search Desc")
    : t("No new license desc");

  const [addNewLicense, addNewLicenseResponse] = useAddNewLicenseMutation();

  const [showModal, setShowModal] = useState(false);

  const AddLicenseButton = (props) => {
    return (
      <BaseButton
        onClick={() => {
          setShowModal(true);
        }}
      >
        إضافة رخص الحفر
      </BaseButton>
    );
  };

  const onLeavePageHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الرخص`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("drilling licences")}
        description={t(
          "تمكنك هذه الصفحة من البحث عن رخص الحفر واستعراض تفاصيل الرخصة"
        )}
        action={
          <Stack direction={"column"} alignItems={"flex-end"}>
            <Box mb={2}>
              <BaseAlert
                show={alert.show}
                type={alert.type}
                message={alert.message}
                destroy={hideAlertHandler}
                autoClose={5}
                closeIcon={false}
              />
            </Box>
            <Box>
              <AddLicenseButton />
            </Box>
          </Stack>
        }
      />

      <AdminAddAttachment
        title="إضافة رخص الحفر"
        show={showModal}
        onHide={onLeavePageHandler}
        name={"license-attachment"}
        onSubmit={(attachment) => {
          const formData = new FormData();
          formData.append("file", attachment.file);
          addNewLicense(formData)
            .unwrap()
            .then((d) => {
              showAlertHandler({
                show: true,
                message: messages.SUCCESS_CON_SAVE_ATTACHMENT,
              });
            })
            .catch((err) => {
              showAlertHandler({
                show: true,
                message: err.data.errorCode,
                type: AlertTypes.ERROR,
              });
            });
        }}
        isSubmitting={addNewLicenseResponse?.isLoading}
        isSuccess={addNewLicenseResponse?.isSuccess}
        isError={addNewLicenseResponse?.isError}
      />

      <Search
        getSearchValue={getLicenseNumber}
        getDropdownValue={getLicenseYear}
        input={{ type: "number", placeholder: "يمكنك البحث برقم الرخصة" }}
        dropdown={{
          label: "سنة الإصدار",
          placeholder: "حدد سنة الإصدار",
          data: licenseYearsData(),
        }}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={adminDrillingLicenses}
          content={getAdminLicensesResponse.data?.content || []}
          isLoading={getAdminLicensesResponse.isFetching}
          injectProps={{
            tableId: TABLE_ID.ADMIN_LICENSES,
            testType: TestTypes.FIELD_TEST,
          }}
          totalCount={20}
          emptyData={{
            title: emptyTitle,
            desc: emptyDesc,
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/drilling-licenses/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "رخصة",
            onChangePaginationDataHandler,
            totalCount: getAdminLicensesResponse.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default AdminDrillingLicenses;
