import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { ContractingCompanies } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import {
  useAddNewContractorCompanyMutation,
  useLazyGetCompaniesQuery,
} from "modules/Admin/apis/contractor-apis/companies-apis";
import { useEffect, useState } from "react";
import { isContentReady } from "utils/Array/Arrays";
import BaseSearch from "core-ui/BaseSearch/BaseSearch";

import { ContractingCompaniesBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import BaseButton from "core-ui/BaseButton/BaseButton";
import AdminAddAttachment from "components/AllModalContent/AdminAddAttachment/AdminAddAttachment";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import messages from "services/AlertMessages/messages";
import { Box, Stack } from "@mui/material";

const ContractingCompaniesIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const [companyName, setCompanyName] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getCompanies, getCompaniesResponse] = useLazyGetCompaniesQuery();

  useEffect(() => {
    var timer = setTimeout(
      getCompanies.bind(null, {
        page,
        pageSize,
        sortId: "id,desc",
        nameQuery: companyName,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, companyName]);

  const getCompanyName = (companyName) => {
    setCompanyName(companyName);
  };

  const isNoSearchData =
    companyName && !isContentReady(getCompaniesResponse?.data?.content);
  const emptyTitle = isNoSearchData ? t("No Search Company") : t("No Company");
  const emptyDesc = isNoSearchData ? t("No Search Desc") : t("No Company Desc");

  const [addNewContractorCompany, addNewContractorCompanyResponse] =
    useAddNewContractorCompanyMutation();

  const [showModal, setShowModal] = useState(false);
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const AddCompanyButton = (props) => {
    return (
      <BaseButton
        onClick={() => {
          setShowModal(true);
        }}
      >
        إضافة شركة مقاولات
      </BaseButton>
    );
  };

  const onLeavePageHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`شركات المقاولين`}</title>
        <link rel="canonical" href="/companies" />
      </Helmet>

      <DashboardIntro
        title={t("contracting companies")}
        breadcrumbData={ContractingCompaniesBreadcrumb(t)}
        description={t(
          "تمكنك هذه الصفحة من الاطلاع على البيانات الخاصة بشركات المقاولات مثل بيانات الشركة ورصيد النقاط الحالي للشركة"
        )}
        action={
          <Stack direction={"column"} alignItems={"flex-end"}>
            <Box mb={2}>
              <BaseAlert
                show={alert.show}
                type={alert.type}
                message={alert.message}
                destroy={hideAlertHandler}
                autoClose={5}
                closeIcon={false}
              />
            </Box>
            <Box>
              <AddCompanyButton />
            </Box>
          </Stack>
        }
      />

      <AdminAddAttachment
        title="إضافة شركة مقاولات"
        show={showModal}
        onHide={onLeavePageHandler}
        name={"company-attachment"}
        onSubmit={(attachment) => {
          const formData = new FormData();
          formData.append("file", attachment.file);
          addNewContractorCompany(formData)
            .unwrap()
            .then((d) => {
              showAlertHandler({
                show: true,
                message: messages.SUCCESS_CON_SAVE_ATTACHMENT,
              });
            })
            .catch((err) => {
              showAlertHandler({
                show: true,
                message: err.data.errorCode,
                type: AlertTypes.ERROR,
              });
            });
        }}
        isSubmitting={addNewContractorCompanyResponse?.isLoading}
        isSuccess={addNewContractorCompanyResponse?.isSuccess}
        isError={addNewContractorCompanyResponse?.isError}
      />

      <BaseSearch
        placeholder="يمكنك البحث باسم الشركة أو الرقم الموحد للمنشأة"
        name="companyName"
        getSearchTerm={getCompanyName}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={ContractingCompanies}
          content={getCompaniesResponse?.data?.content || []}
          isLoading={getCompaniesResponse.isFetching}
          injectProps={{
            tableId: TABLE_ID.ADMIN_CONTRACTING_COMPANIES,
          }}
          totalCount={20}
          emptyData={{
            title: emptyTitle,
            desc: emptyDesc,
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/companies/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "شركات",
            onChangePaginationDataHandler,
            totalCount: getCompaniesResponse?.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default ContractingCompaniesIndex;
