import { useLocation, useOutletContext, useParams } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ExaminationRequests } from "services/StaticLookup/Breadcrumb";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { useState } from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { AlertTypes } from "constants/AlertTypes";
import {
  useGetInspectionAsphaltReportQuery,
  useGetInspectionSoilReportQuery,
  useGetLabTestAppointmentForContractorQuery,
  useCancelRequestMutation,
  useGetInspectionFieldReportQuery,
} from "modules/Contractor/apis/contractor-resource/contractor-resource";
import {
  useCheckEditableForContractorQuery,
  useGetContractorInspectionQuery,
} from "modules/Contractor/apis/inspection-apis/inspection-api";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useGetLicenseDetailsQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import { useRescheduleAppointmentMutation } from "modules/Contractor/apis/appointments-apis/appointments-apis";
import { TestTypes } from "constants/TestTypes";
import { DATA_ID } from "constants/Details";
import RescheduleModal from "modules/Contractor/components/RescheduleModal/RescheduleModal";
import CancelModal from "modules/Contractor/components/CancelModal/CancelModal";
import BaseButton from "core-ui/BaseButton/BaseButton";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { REQUEST_TYPE } from "constants/RequestType";
import { base64ToBlob } from "utils/ApiHelpers";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { useGetAspeltReportStatusQuery } from "modules/Contractor/apis/inspection-apis/asphalt-api";
import FieldExaminationRequests from "./Field";
import LabExaminationRequests from "./Lab";
import { formattedNumber } from "utils/Numbers/Numbers";
import { ReactComponent as EditingIcon } from "assets/icons/editing.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { windowOpenPDFHandler } from "utils/File/File";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";

const ExaminationRequestsDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { globalAlert, onHideGlobalAlert, onShowGlobalAlert } =
    useOutletContext();

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const { palette } = useTheme();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.activeTab) {
      setTabsActiveIndex(state?.activeTab);
    }
  }, []);

  const {
    data: inspection,
    isSuccess,
    isFetching,
    refetch,
  } = useGetContractorInspectionQuery(params.id);

  const { data: labTestAppointmentForContractor } =
    useGetLabTestAppointmentForContractorQuery(params.id);

  const { data: licenseData } = useGetLicenseDetailsQuery(
    inspection?.licenses?.[0]?.id,
    { skip: !inspection?.licenses?.[0]?.id }
  );

  const isField = inspection?.testType === TestTypes.FIELD_TEST;
  const isLab = inspection?.testType === TestTypes.LAB_TEST;
  const inspectionLayer = inspection?.inspectionLayersList?.[0];
  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const isSoil = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_SOIL
  );
  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );

  const isRequestDone =
    inspection?.status === "SUCCEED" || inspection?.status === "FAILED";

  const isRequestDoneOrSkipped =
    isRequestDone || inspection?.status === "SKIPPED";

  const { data: asphaltReportData, isFetching: isAsphaltReportDataFetching } =
    useGetAspeltReportStatusQuery(params?.id, {
      skip: true || !params?.id || !isLab || !isAsphalt,
    });

  const {
    data: inspectionAsphaltReport,
    isLoading: isLoadingInspectionAsphaltReport,
  } = useGetInspectionAsphaltReportQuery(inspectionLayer?.id, {
    skip:
      !isLab ||
      !isAsphalt ||
      (inspection?.status !== REQUEST_TYPE.SUCCEED &&
        inspection?.status !== REQUEST_TYPE.FAILED),
  });

  const {
    data: inspectionSoilReport,
    isLoading: isLoadingInspectionSoilReport,
  } = useGetInspectionSoilReportQuery(inspectionLayer?.id, {
    skip:
      !isLab ||
      (!isABC && !isSoil) ||
      (inspection?.status !== REQUEST_TYPE.SUCCEED &&
        inspection?.status !== REQUEST_TYPE.FAILED),
  });

  const {
    data: inspectionFieldReport,
    isLoading: isLoadingInspectionFieldReport,
  } = useGetInspectionFieldReportQuery(inspection?.id, {
    skip: !inspection?.id || !isField || !isSoil || !isRequestDoneOrSkipped,
  });

  const [rescheduleAppointment, rescheduleAppointmentResponse] =
    useRescheduleAppointmentMutation();

  const [cancelRequest, cancelRequestResponse] = useCancelRequestMutation();

  const canReschedule =
    licenseData &&
    inspection?.status === REQUEST_TYPE.OUTDATED &&
    !licenseData?.certificateIssued;

  const canCancel = inspection?.isEligibleToCancel;

  const totalPoints = isLab
    ? inspection?.combinedLabTestCost + inspection?.sampleCollectionCost
    : inspection?.combinedFieldTestCost;

  const { data: checkEditable } = useCheckEditableForContractorQuery(
    inspection?.id,
    {
      skip: isLab || !inspection?.id,
    }
  );
  const isEditable = checkEditable === "CAN_EDIT";

  const confirmCancel = (cancelReason, reset) => {
    const body = {
      inspectionId: inspection?.id,
      cancellationReason: cancelReason,
    };
    cancelRequest(body)
      .unwrap()
      .then(() => {
        setShowCancelModal(false);
        reset({ cancelReason: "" });
        onShowGlobalAlert({
          show: true,
          message: null,
          type: AlertTypes.SUCCESS,
        });
        refetch();
      })
      .catch((err) => {
        setShowCancelModal(false);
        onShowGlobalAlert({
          show: true,
          message:
            err?.data?.errorCode ||
            ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE ||
            ErrorCodes.REQUEST_CANNOT_BE_CANCELLED,
          type: AlertTypes.ERROR,
        });
      });
  };

  const confirmReschedule = (appointmentData) => {
    const body = {
      contractorId: inspection.contractorId,
      labId: null,
      inspection: inspection.id,
      ...appointmentData,
    };

    rescheduleAppointment(body)
      .unwrap()
      .then((responseData) => {
        refetch();
        setShowRescheduleModal(false);
        onShowGlobalAlert({
          show: true,
          type: AlertTypes.WARNING,
          message: SuccessCodes.SUCCESS_APPOINTMENT_RESCHEDULE,
          interpolation: {
            points: isLab
              ? inspection?.combinedLabTestCost +
                inspection?.sampleCollectionCost
              : inspection?.combinedFieldTestCost,
          },
        });
      })
      .catch((err) => {
        setShowRescheduleModal(false);
        onShowGlobalAlert({
          show: true,
          message:
            err.data?.errorCode === ErrorCodes.CONTRACTOR_BALANCE
              ? ErrorCodes.CONTRACTOR_NO_BALANCE
              : err.data?.errorCode || ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
          type: AlertTypes.ERROR,
        });
      });
  };

  const RescheduleButton = () => {
    return canReschedule ? (
      <BaseButton
        sx={{ minWidth: "21rem" }}
        onClick={() => setShowRescheduleModal(true)}
        disabled={isLoadingInspectionAsphaltReport}
      >
        {t("resubmit request", {
          ns: "dashboard",
        })}
      </BaseButton>
    ) : null;
  };

  const CancelButton = () => {
    return canCancel ? (
      <BaseButton
        sx={{ minWidth: "2.1rem" }}
        onClick={() => setShowCancelModal(true)}
        variant="secondary"
      >
        {t("إلغاء الطلب", {
          ns: "dashboard",
        })}
        <CloseIcon
          fontSize="large"
          sx={{
            color: "grayDark.color",
            fontWeight: "bold",
          }}
        />
      </BaseButton>
    ) : null;
  };

  const EditButton = () => {
    const handleEditClick = () => {
      navigate(`/examination-requests/${inspection?.id}/edit-request`);
    };
    return (
      <BaseButton
        sx={{ minWidth: "2.1rem" }}
        onClick={handleEditClick}
        variant="secondary"
      >
        {t("تعديل الطلب", {
          ns: "dashboard",
        })}
        <Typography component={"span"} sx={{ ml: "0.30rem" }}>
          <EditingIcon />
        </Typography>
      </BaseButton>
    );
  };

  const openPDFHandler = () => {
    const blob = base64ToBlob(inspectionAsphaltReport?.report);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  const openSoilPDFHandler = () => {
    const blob = base64ToBlob(inspectionSoilReport?.report);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  const ViweAsphaltReport = () => {
    return (
      <BaseButton
        disabled={!isRequestDone || !inspectionAsphaltReport?.report}
        sx={{ minWidth: "21rem" }}
        onClick={openPDFHandler}
        isLoading={isLoadingInspectionAsphaltReport}
      >
        {"تحميل تقرير الأسفلت"}
      </BaseButton>
    );
  };

  const ViweSoilReport = () => {
    return (
      <BaseButton
        disabled={!isRequestDone || !inspectionSoilReport?.report}
        sx={{ minWidth: "21rem" }}
        onClick={openSoilPDFHandler}
        isLoading={isLoadingInspectionSoilReport}
      >
        {"تحميل تقرير التربة"}
      </BaseButton>
    );
  };

  const ViweFieldReport = () => {
    return (
      <BaseButton
        disabled={!isRequestDoneOrSkipped || !inspectionFieldReport?.report}
        sx={{ minWidth: "21rem" }}
        onClick={windowOpenPDFHandler.bind(null, inspectionFieldReport?.report)}
        isLoading={isLoadingInspectionFieldReport}
      >
        {"تحميل تقرير الاختبار الحقلي"}
      </BaseButton>
    );
  };

  useEffect(() => {
    if (state?.fromPageId === "SUCCESS_UPDATE_REQUEST") {
      onShowGlobalAlert({
        show: true,
        message: SuccessCodes.SUCCESS_UPDATE_REQUEST,
        type: AlertTypes.SUCCESS,
      });
    }
  }, [state?.fromPageId]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات الفحص - رقم الطلب	${inspection?.id}`}</title>
      </Helmet>

      <DashboardIntro
        title={t(` رقم الطلب ${inspection?.id || ""}`, {
          ns: "dashboard",
        })}
        description={t(
          isAsphalt && isLab
            ? "يمكنك متابعة تقدم اختبارات الفحص و الاطلاع على النتائج كما يمكنك تحميل تقرير الAsphalt بعد اكتمال الطللب "
            : "يمكنك طباعة شهادة إتمام الفحص عند اجتياز جميع الاختبارات بنجاح",
          { ns: "dashboard" }
        )}
        action={
          <Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 3,
                mb: 2,
              }}
            >
              <RescheduleButton />

              <CancelButton />
              {!isLab && isEditable && <EditButton />}

              {!canReschedule && inspectionLayer?.isAsphalt && isLab && (
                <ViweAsphaltReport />
              )}
              {!canReschedule && (isSoil || isABC) && isLab && (
                <ViweSoilReport />
              )}
              {isRequestDoneOrSkipped && isSoil && isField && (
                <ViweFieldReport />
              )}
            </Box>

            {state?.fromPageId === "newInspection" && (
              <BaseAlert
                type={AlertTypes.SUCCESS}
                color={palette.base.successBorder}
                message={"تم إرسال الطلب بنجاح"}
                autoClose={5}
                labelSx={{ fontWeight: "bold" }}
              />
            )}

            <BaseAlert
              show={globalAlert.show}
              type={globalAlert.type}
              message={globalAlert.message}
              autoClose={6}
              interpolation={globalAlert.interpolation}
              destroy={onHideGlobalAlert}
              icon={false}
            >
              <span>
                {!globalAlert.message && (
                  <Typography
                    sx={{
                      fontSize: "inherit !important",
                      fontWeight: "bold",
                      color: "grayDark.color1",
                    }}
                  >
                    تم إلغاء هذا الطلب بناء على طلبكم، كما تم إرجاع{" "}
                    <span
                      style={{
                        fontSize: "1.8rem",
                        fontWeight: "bold",
                        color: palette.primary.colorSoft10,
                      }}
                    >
                      {formattedNumber(totalPoints)}
                    </span>{" "}
                    نقطة للمحفظة بنجاح.
                  </Typography>
                )}
              </span>
            </BaseAlert>
          </Box>
        }
        breadcrumbData={ExaminationRequests({
          id: inspection?.id,
        })}
      />

      <RescheduleModal
        isField={!isLab}
        isLab={isLab}
        show={showRescheduleModal}
        closeModal={() => setShowRescheduleModal(false)}
        confirmReschedule={confirmReschedule}
        id={inspection?.id}
        totalPoints={totalPoints}
        isLoading={rescheduleAppointmentResponse.isLoading}
      />
      <CancelModal
        show={showCancelModal}
        closeModal={() => setShowCancelModal(false)}
        confirmCancel={confirmCancel}
        id={inspection?.id}
        isLoading={cancelRequestResponse.isLoading || isFetching}
        totalPoints={totalPoints}
      />

      <BaseCard
        mt={6}
        py={"2rem"}
        px={"5rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        {isFetching || !isSuccess ? (
          <BaseLoader />
        ) : (
          tabsActiveIndex === 1 &&
          (isLab ? (
            <LabExaminationRequests
              title={"تفاصيل الطلب"}
              requestDetails={inspection}
              labtestappointment={labTestAppointmentForContractor}
              isAsphalt={isAsphalt}
              inspectionLayer={inspectionLayer}
              dataId={DATA_ID.CON_REQUESTS}
              asphaltReportData={{
                data: asphaltReportData,
                isFetching: isAsphaltReportDataFetching,
              }}
              isFetching={isFetching}
              canReschedule={canReschedule}
            />
          ) : (
            <FieldExaminationRequests
              dataId={DATA_ID.CON_REQUESTS}
              requestDetails={inspection}
              labtestappointment={labTestAppointmentForContractor}
              isFetching={isFetching}
              canReschedule={canReschedule}
            />
          ))
        )}
      </BaseCard>
    </>
  );
};

export default ExaminationRequestsDetails;
