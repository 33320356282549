import { Box, Stack, Typography } from "@mui/material";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";
import LocationField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LocationField/LocationField";
import { defaultEmptyValue } from "constants/Empty";
import { TABLE_ID } from "constants/Table";
import BaseFileDataItem from "core-ui/BaseFileDataItem/BaseFileDataItem";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import React, { useMemo } from "react";
import { formatDateYYYYMMDD, renderDateTimeInAMPM } from "utils/Date/Date";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useTranslation } from "react-i18next";
import DisplayResultTest from "components/DisplayResultTest/DisplayResultTest";
import DisplayMinMaxSingleScores from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayMinMaxSingleScores/DisplayMinMaxSingleScores";
import { enLabelSx } from "constants/Typography";
import LongLength from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LongLength/LongLength";
import { KeyboardArrowDown } from "@mui/icons-material";
import DoubleLayerData from "./DoubleLayerData";
import DisplayScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayScore/DisplayScore";
import Notes from "components/Notes/Notes";
import MixDesignDetails from "modules/Laboratory/components/MixDesignDetails/MixDesignDetails";
import MultipleLicensesDataItem from "components/MultipleLicensesDataItem/MultipleLicensesDataItem";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import LicenseLink from "components/LicenseLink/LicenseLink";

function ExaminationDataLab({
  request,
  dataId,
  labTestAppointment,
  asphalet,
  inspectionTestsData,
  isFetching,
  AspeltBaseCourseAttachment,
  subTests,
  inspectionLayer,
  isABC,
  ...props
}) {
  const createdAtDateObject = new Date(request?.createdAt);
  const requestCreatedAtTime = renderDateTimeInAMPM(
    `${createdAtDateObject.getHours()}:${createdAtDateObject.getMinutes()}:00`
  );
  const { t } = useTranslation();

  const inspectionTests = inspectionTestsData?.map(
    (inspection) => inspection?.inspectionTests
  );

  const contractorExaminationDetailsTests = useMemo(() => [
    {
      serverKey: "testName",
      name: "test name",
      format: (cell, row) => {
        return (
          <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
            {cell}
          </Typography>
        );
      },
    },
    {
      serverKey: "testStandard",
      name: "testـstandards",
      hasComponent: { customProps: "testStandard", comp: <LongLength /> },
      fallback: "لا يوجد",
    },
    {
      name: "limits",
      hasComponent: { comp: <DisplayMinMaxSingleScores /> },
      fallback: "لا يوجد",
    },
    {
      serverKey: "status",
      name: "status",
      hasComponent: { comp: <BaseStatus /> },
    },
    {
      serverKey: "score",
      name: "score percentage",
      hasComponent: { comp: <DisplayScore /> },
    },
    {
      serverKey: ["result", "status"],
      name: "Result",
      show: !asphalet,
      hasComponent: {
        comp: <DisplayResultTest />,
      },
    },
  ]);

  const isRequestDone =
    request?.status === "SUCCEED" || request?.status === "FAILED";

  const isSingleLicense = request?.licenses?.length === 1;

  return (
    <Box>
      <Box className={"request-data-details"} mt={4}>
        <Box className={"request-data-details__wrapper"} mb={5}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {request?.id}
              </Typography>
            </Box>
            {isSingleLicense ? (
              <LicenseLink license={request?.licenses?.[0]} />
            ) : (
              <MultipleLicensesDataItem
                requestId={request?.id}
                data={request?.licenses}
              />
            )}
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الفحص
              </Typography>
              <BaseStatus
                status={request?.status}
                dataId={dataId}
                testType={request?.testType}
                appointment={request?.appointment}
              />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الإنشاء
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(request?.createdAt)}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                وقت الإنشاء
              </Typography>
              <Typography className={"request-data-details__value"}>
                {requestCreatedAtTime}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اختبار مواد الأعمال المدنية
              </Typography>
              <Typography className={"request-data-details__value"}>
                {request?.name}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                {"موعد جمع العينة"}
              </Typography>
              <Typography
                component={"div"}
                className={"request-data-details__value"}
              >
                <DisplayRequestAppointmentDate
                  appointmentDate={labTestAppointment?.scheduledDate}
                  scheduledAppointment={labTestAppointment?.scheduledTime}
                />
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                مسار الفحص
              </Typography>
              <BaseTypeTag type={request?.testType} />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                المدة المتوقعة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {request?.contractorEstimatedTime || "لايوجد"} أيام
              </Typography>
            </Box>
            {isABC && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  درجة التربة
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {inspectionLayer?.soilGrade || defaultEmptyValue}
                </Typography>
              </Box>
            )}
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                {`موقع أخذ العينة`}
              </Typography>
              {inspectionLayer?.latitude ? (
                <LocationField
                  location={`https://maps.google.com/?q=${inspectionLayer?.latitude},${inspectionLayer?.longitude}`}
                />
              ) : (
                <Typography className={"request-data-details__value"}>
                  {defaultEmptyValue}
                </Typography>
              )}
            </Box>
            {asphalet && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  ملف تصميم خلطة الاسفلت (Asphalt Mix Design)
                </Typography>
                <BaseFileDataItem
                  label=" Asphalt.pdf"
                  attachment={AspeltBaseCourseAttachment}
                />
              </Box>
            )}
          </WrapperContentGrid>
        </Box>
        <hr />
        {inspectionLayer?.requiresAspeltReport && asphalet && (
          <Box>
            <Box mt={5}>
              <Box mt={4} mb={3}>
                <Notes
                  notes={inspectionLayer.aspeltMixDesignDetailDto?.notes}
                />
              </Box>
              <Box mt={4} mb={7}>
                <MixDesignDetails
                  aspeltMixDesignDetailDto={
                    inspectionLayer.aspeltMixDesignDetailDto
                  }
                />
              </Box>
              <hr />
            </Box>
          </Box>
        )}
        <Box mt={5}>
          <BaseTableDoubleCard
            title={"الاختبارات"}
            description={
              "تم تحديد الاختبارات التالية بناء على نوع اختبار مواد الأعمال المدنية و نوع مسار الفحص"
            }
            tableHeader={contractorExaminationDetailsTests}
            content={inspectionTests?.[0] || []}
            isLoading={isFetching}
            emptyData={{
              title: t("No new license"),
              desc: t("No new license desc"),
              img: publicAssetsPath("images/documents.svg"),
            }}
            injectProps={{
              tableId: TABLE_ID.SUB_TEST_STATUS,
              isRequestDone,
              extraStatus: request?.status,
            }}
            doubleLayer={() => {
              return {
                visibility: true,
                component: (
                  <DoubleLayerData
                    subTests={subTests || []}
                    request={request}
                    asphalet={asphalet}
                  />
                ),
              };
            }}
            withSpecificAction={(element, triggeredElements) => {
              return {
                condition: element?.containSubtests,
                actionType: "dropdown",
                component: (
                  <KeyboardArrowDown
                    sx={{
                      fontSize: 38,
                      cursor: "pointer",
                      color: "#347A7C",
                      transition: "all .5s ease",
                      transform: triggeredElements?.[element.id]
                        ? "rotate(180deg)"
                        : "",
                      marginRight: "10px",
                    }}
                  />
                ),
              };
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ExaminationDataLab;
