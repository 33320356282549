import { Box } from "@mui/material";
import BaseRadioWithLabel from "core-ui/BaseRadioWithLabel/BaseRadioWithLabel";
import React from "react";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseTextArea from "core-ui/BaseTextArea/BaseTextArea";

function RecordQuestionnaire(props) {
  return (
    <Box mt={4}>
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل كان الاستشاري متواجد في الموقع عند إجراء الاختبار، وفي الوقت المحدد؟"}
        name={"consultantPresent"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل كان المختبر متواجد في الموقع عند إجراء الاختبار، وفي الوقت المحدد؟"}
        name={"labPresent"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل كان المقاول متواجد في الموقع عند إجراء الاختبار، وفي الوقت المحدد؟"}
        name={"contractorPresent"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل كانت الطبقة جاهزة للاختبار؟"}
        name={"layerReady"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"ما هي حالة الطقس وقت الاختبار؟"}
        name={"weather"}
        data={props.ExaminationRecordWeatherFormData}
        defaultValue={props.ExaminationRecordWeatherFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل بيانات الموقع مطابقة للبيانات المدخلة بالطلب على المنصة؟"}
        name={"locationIsCorrect"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل يوجد ملاحظات على الموقع؟"}
        name={"havaNotes"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={
          "هل يوجد تأخير في اغلاق الطلب في الوقت المحدد؟ أذكر السبب في الملاحظات؟"
        }
        name={"delayCloseRequest"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <hr />
      <Box mt={6}>
        <Box mb={1} display={"flex"} alignItems={"center"} gap={1}>
          <BaseCardTitle>{props.t("notes", { ns: "dashboard" })}</BaseCardTitle>
        </Box>
        <BaseTextArea
          textAreaStyles={{ padding: "2rem" }}
          control={props.control}
          name={"notes"}
          maxLength={500}
          rules={{
            maxLength: {
              value: 500,
              message: `تجاوزت الحد المسموح`,
            },
          }}
          htmlFor={"notes"}
          placeholder={props.t("notes", {
            ns: "dashboard",
          })}
          errors={props.errors}
        />
      </Box>
    </Box>
  );
}

export default RecordQuestionnaire;
